import { Box, Button, Typography } from "@mui/material";
import React from "react";
import useThemeStore from "../../hooks/store/useThemeStore";
import { useAllModal } from "../../hooks/store/useAllModal";
import { useNavigate } from "react-router-dom";
import ROUTE_LIST from "../../Routes/list";

const UserVerificationPending = () => {
  let { darkMode } = useThemeStore();
  const { setShowCommonDrawer } = useAllModal();
  const navigate = useNavigate();

  //   function for send email varification link

  return (
    <Box
      sx={{
        maxWidth: { xs: "300px", md: "400px" },
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Typography
        sx={{
          maxWidth: { xs: "257px", md: "330px" },
          mx: "auto",
          color: darkMode ? "white.1000" : "black.350",
          textAlign: "center",
          fontSize: { xs: "18px", md: "24px" },
          fontStyle: " normal",
          fontWeight: 600,
          lineHeight: "normal",
          letterSpacing: "-0.408px",
        }}
      >
        Verify these details first to Post Your Raise
      </Typography>
      <Typography
        sx={{
          mt: "15px",
          fontWeight: 500,
          fontSize: { xs: "12px", sm: "14px" },
          lineHeight: 1.5,
          mx: "auto",
          color: darkMode ? "white.1000" : "black.350",
          textAlign: "center",
        }}
      >
        Welcome to PloomSocial!
        <br /> Before you start creating a video to fundraise for your nonprofit
        or cause, let's quickly validate your identity. This ensures a safe and
        successful raise for everyone. Ready to proceed?
      </Typography>
      <Box
        sx={{
          display: "flex",
          mt: { xs: "20px", sm: "25px", md: "35px" },
          justifyContent: "space-between",
          gap: { xs: "10px", sm: "25px", md: "35px" },
        }}
      >
        {/* {(kyc === false || kyc === 0 || kyc === 3) && (
          <Button
            onClick={() => {
              navigate(ROUTE_LIST.DRAFT.CREATE);
              setShowCommonDrawer(false);
            }}
            variant={darkMode ? "contained" : "outlined"}
            sx={{
              backgroundColor: darkMode ? "black.900" : "unset",
              width: "100%",
              textTransform: "unset !important",
              padding: "14px",
              fontSize: { xs: "12px", md: "16px" },
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "normal",
              borderRadius: "10px",
            }}
          >
            Save as a Draft
          </Button>
        )} */}

        <Button
          variant="contained"
          onClick={() => {
            setShowCommonDrawer(false);
            // setShowIdentityDrawer(true, "raise");
          }}
          sx={{
            textTransform: "capitalize",
            padding: "14px",
            fontSize: { xs: "12px", md: "16px" },
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "normal",
            borderRadius: "10px",
            width: "100%",
          }}
        >
          Continue
        </Button>
        {/* {emailVerification === false && (
          <LoadingButton
            onClick={sendEmailVerificationLink}
            variant={darkMode ? "contained" : "outlined"}
            loading={loading}
            fullWidth
            size="large"
            type="submit"
            sx={{
              mt: { xs: "10px", md: "15px" },
              backgroundColor: darkMode ? "black.900" : "unset",
              textTransform: "capitalize",
              padding: { xs: "14px 32px 14px 38px", md: "13px 83px 12px 85px" },
              fontSize: { xs: "16px", md: "18px" },
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "normal",
              letterSpacing: " -0.408px",
              borderRadius: "10px",
              "&.Mui-disabled": {
                // color: darkMode ? alpha(palette.white["1000"], 0.7) : "black.100",
                backgroundColor: darkMode
                  ? "primary.disabledDark"
                  : "primary.disabledLight",
              },
              "& .MuiCircularProgress-root": {
                color: "white.1000",
              },
            }}
          >
            Email Verification
          </LoadingButton>
        )} */}
      </Box>
    </Box>
  );
};

export default UserVerificationPending;
