import {
  Box,
  Button,
  Typography,
  IconButton,
  SwipeableDrawer,
  alpha,
  useTheme,
} from "@mui/material";
import React from "react";
import useThemeStore from "../../hooks/store/useThemeStore";
import ROUTE_LIST from "../../Routes/list";
import DrawerBg from "../../icons/DrawerBg";
import CloseIcon from "../../icons/CloseIcon";
import { useNavigate } from "react-router-dom";
import { useUserVerification } from "../../hooks/store/useUserVerification";

export default function UserConcernDialog({
  openDrawer,
  setOpenDrawer,
  isLoading,
  callback,
}) {
  const { palette } = useTheme();
  const { darkMode } = useThemeStore();
  const navigate = useNavigate();
  let { kyc } = useUserVerification();
  const iOS =
    typeof navigator !== "undefined" &&
    /iPad|iPhone|iPod/.test(navigator.userAgent);

  function HandleClick(e) {
    e.stopPropagation();
  }

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={openDrawer}
      disableBackdropTransition={!iOS}
      disableDiscovery={iOS}
      onOpen={() => {}}
      onClose={() => {}}
      onClick={() => {
        setOpenDrawer(false);
      }}
      sx={{
        zIndex: 4000,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        paddingInline: "15px",
        backgroundColor: alpha(palette.black["1000"], 0.5),
        "& *.css-18tmwsn": {
          backgroundColor: "transparent",
          maxWidth: "550px",
          margin: "0 auto",
        },
        "& .css-4w5voh-MuiPaper-root-MuiDrawer-paper": {
          background: "transparent",
          maxWidth: "550px",
          margin: "0 auto",
        },
        "& .MuiPaper-root": {
          position: "relative",
        },
      }}
    >
      <Box
        sx={{
          position: "relative",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          maxWidth: "574px",
          width: "100%",
          minHeight: "533px",
          marginInline: "auto",
          backgroundColor: darkMode ? "bgDark.third" : "white.1000",
          borderRadius: "15px",
          overflowX: "hidden",
        }}
        onClick={HandleClick}
      >
        {/* background */}
        <Box sx={{ position: "absolute", top: "35px", left: "-70px" }}>
          <DrawerBg
            color={darkMode ? palette.grey["550"] : palette.primary["light50"]}
            width={"220px"}
            height={"253px"}
          />
        </Box>
        <Box
          sx={{
            position: "absolute",
            bottom: "29px",
            right: "-70px",
            transform: "rotate(180deg)",
          }}
        >
          <DrawerBg
            color={darkMode ? palette.grey["550"] : palette.primary["light50"]}
            width={"220px"}
            height={"253px"}
          />
        </Box>

        {/* close button */}
        <IconButton
          aria-label="delete"
          size="small"
          sx={{
            position: "absolute",
            top: "15px",
            right: "15px",
          }}
          onClick={() => setOpenDrawer(false)}
        >
          <CloseIcon
            color={
              darkMode
                ? alpha(palette.white["1000"], 0.7)
                : palette.black["1000"]
            }
          />
        </IconButton>

        <Box
          sx={{
            maxWidth: { xs: "300px", md: "400px" },
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography
            sx={{
              maxWidth: { xs: "257px", md: "330px" },
              mx: "auto",
              color: darkMode ? "white.1000" : "black.350",
              textAlign: "center",
              fontSize: { xs: "18px", md: "24px" },
              fontStyle: " normal",
              fontWeight: 600,
              lineHeight: "normal",
              letterSpacing: "-0.408px",
            }}
          >
            {/* Verify these details first to Post Your Raise */}
            Email Verification Required
          </Typography>
          <Typography
            sx={{
              mt: "15px",
              fontWeight: 500,
              fontSize: { xs: "12px", sm: "14px" },
              lineHeight: 1.5,
              mx: "auto",
              color: darkMode ? "white.1000" : "black.350",
              textAlign: "center",
            }}
          >
            Welcome to PloomSocial!
            <br /> To complete your campaign creation, please verify your email
            for added account security. This is a one-time verification, and it
            ensures you’ll receive important updates on your campaign’s
            progress. Check your inbox for the verification link to finish
            setting up your campaign!
          </Typography>
          <Box
            sx={{
              display: "flex",
              mt: { xs: "20px", sm: "25px", md: "35px" },
              justifyContent: "space-between",
              gap: { xs: "10px", sm: "25px", md: "35px" },
            }}
          >
            {/* {(kyc === false || kyc === 0 || kyc === 3) && ( */}
            <Button
              onClick={() => {
                navigate(ROUTE_LIST.DRAFT.CREATE);
                setOpenDrawer(false);
                callback();
              }}
              variant={darkMode ? "contained" : "outlined"}
              sx={{
                backgroundColor: darkMode ? "black.900" : "unset",
                width: "100%",
                textTransform: "unset !important",
                padding: "14px",
                fontSize: { xs: "12px", md: "16px" },
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "normal",
                borderRadius: "10px",
              }}
            >
              Save as a Draft
            </Button>
            {/* )} */}

            <Button
              variant="contained"
              onClick={() => {
                setOpenDrawer(false);
                callback();
              }}
              sx={{
                textTransform: "capitalize",
                padding: "14px",
                fontSize: { xs: "12px", md: "16px" },
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "normal",
                borderRadius: "10px",
                width: "100%",
              }}
            >
              Continue
            </Button>
          </Box>
        </Box>
      </Box>
    </SwipeableDrawer>
  );
}
