import React, { useCallback, useEffect, useState } from "react";
import { Box, Button, Stack, Typography, alpha, Collapse } from "@mui/material";

import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
// import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import DragAndDropInput from "../component/ui/form/DragAndDropInput";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Input from "../component/ui/form/Input";
import moment from "moment";
import useApi from "../hooks/useApi";
import axios from "axios";
import { useAuth } from "../hooks/store/useAuth";
import { useSnack } from "../hooks/store/useSnack";
import DynamicMeta from "../utils/DynamicMeta";
import useThemeStore from "../hooks/store/useThemeStore";
import { useTheme } from "@emotion/react";
import ButtonWithLoading from "../component/ui/button/ButtonWithLoading";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { useUserVerification } from "../hooks/store/useUserVerification";
import { useAllModal } from "../hooks/store/useAllModal";
import FileUploadingDialog from "../component/ui/FileUploadingDialog";
import ContactUsDialog from "../component/indetityVerification/ContactUsDialog";
import DialogBox from "../component/Dialogbox/DialogBox";
import VideoRequired from "../component/feed/dialog/ErrorDialog";
import { useDraft } from "../hooks/store/useDraft";
import StoryIcon from "../icons/StoryIcon";
import RaiseIcon from "../icons/RaiseIcon";
import { useSettings } from "../hooks/store/useSettings";
import EmailVerifyDialog from "../component/verifyOldUser/EmailVerifyDialog";
import UserConcernDialog from "../component/commonDrawer/UserConcernBeforeCreateRaise";

export const CreateRaise = () => {
  const { darkMode } = useThemeStore();
  // const [currentDate, setCurrentDate] = React.useState(dayjs("2022-04-17"));
  const { palette } = useTheme();
  const { apiCall } = useApi();
  const { apiCall: createFeedCall, isLoading } = useApi();
  const { apiCall: API } = useApi(true);
  const { userId, user } = useAuth();

  const { setSnack } = useSnack();
  const [loading, setLoading] = useState(false);
  const [postDescription, setPostDescription] = useState("");
  const [title, setTitle] = useState("");
  const [uploadinProgress, setUploadinProgress] = useState(false);
  const [openContactUsDialog, setOpenContactUsDialog] = useState(false);
  const [currentFileIndex, setCurrentFileIndex] = useState(0);
  const [totalFiles, setTotalFiles] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [videoPopup, setVideoPopup] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadError, setUploadError] = useState([]); //store index of current uploading file when error in comes
  const [uploadedVideos, setUploadedVideos] = useState([]);
  // const [storyData, setStoryData] = useState();
  const [awsUploading, setAwsUploading] = useState(true);
  const [finalVideo, setFinalVideo] = useState([]);
  const [formValues, setFormValues] = useState({});
  const [chooseType, setChooseType] = useState("campaign"); // 1.campaign 2. story
  const { setDraftObject } = useDraft();
  const { settings } = useSettings();
  const [openEmailDialog, setOpenEmailDialog] = useState(false);
  const [openUserConcernDrawer, setOpenUserConcernDrawer] = useState(false);
  const [isUserVerified, setIsUserVerified] = useState(false);

  // code for upload files in aws

  const uploadFileInAWS = async (files) => {
    setUploadinProgress(true);
    setTotalFiles(files);
    setOpenDialog(true);
    setUploadError([]);
    const videoArray = [...uploadedVideos];
    try {
      for (let i = videoArray.length; i < files.length; i++) {
        try {
          setCurrentFileIndex(i);
          setUploadProgress(0);
          let res;
          const unique = Date.now() + Math.ceil(Math.random() * 1e9).toString(),
            isVideo = files[i].file.type.includes("video");
          res = await apiCall({
            url: isVideo
              ? "v3/get-signed-url"
              : "v3/get-signed-url/begs/" + unique + ".jpeg",
          });
          if (res.status === 200) {
            res = res.data.data;
            await axios({
              method: "put",
              url: res.url,
              headers: {
                "Content-Type": files[i].file.type,
              },
              onUploadProgress: function (progressEvent) {
                const percentCompleted = Math.round(
                  (progressEvent.loaded * 100) / progressEvent.total
                );
                setUploadProgress(percentCompleted);
              },
              data: files[i].file,
            });
            videoArray.push({
              videoLink: isVideo ? res.uuid : unique,
              thumbLink: isVideo ? res.uuid : unique,
              type: isVideo ? "video" : "image",
            });
            setUploadedVideos(videoArray);
          } else {
            throw Error("link can not get");
          }
          setTotalFiles((prevVideo) =>
            prevVideo.map((video, index) => {
              if (index === i) {
                video.uploaded = true;
              }
              return video;
            })
          );
        } catch (error) {
          setUploadError((prevError) => [...prevError, i]);
          setTotalFiles((prevVideo) =>
            prevVideo.map((video, index) => {
              if (!video.uploadError) {
                video.uploadError = index === i;
              }
              return video;
            })
          );
          console.log("error");
          continue;
        }
      }
      setAwsUploading(false);
    } catch (error) {
      setAwsUploading(false);
      throw error;
    }
    setFinalVideo(videoArray);
    return videoArray;
    //  files.map((file) => {
    //   console.log(file.file);
    //   return file;
    // });
  };

  async function createRaiseApiCall() {
    try {
      setAwsUploading(false);
      if (!finalVideo.length) {
        return;
      }
      const data = {
        userId,
        title: formValues.title,
        textDescription: formValues.postDescription,
        htmlDescription: formValues.postDescription,
        goalDate:
          chooseType === "campaign"
            ? moment(formValues.goalDate).format("l")
            : undefined,
        goalAmount: chooseType === "campaign" ? formValues.amount : undefined,
        links: finalVideo,
        begType: chooseType === "campaign" ? 1 : 2,
      };
      const res = await createFeedCall({
        url: "v3/begs/create-v2",
        data,
        method: "post",
      });
      setLoading(false);
      setUploadinProgress(false);
      // setOpenDialog(false);
      setSnack(res.data.message, "success");
      return res.data.data;
      // navigate("/");
    } catch (error) {
      // setStoryData(formValues);
      console.log(error);
      setSnack(
        "Something went wrong while uploading your videos/images. Please try again.",
        "error"
      );
      setUploadinProgress(false);
      setLoading(false);
    }
  }
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (uploadinProgress) {
        event.preventDefault();
        event.returnValue =
          "Are you sure you want to leave? Your upload is in progress. Leaving now may result in an incomplete upload. Do you want to continue and leave the page?";
      }
    };

    const handlePopState = () => {
      if (
        uploadinProgress &&
        window.confirm(
          "Your upload is in progress. Are you sure you want to leave?"
        )
      ) {
        window.history.back();
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    window.addEventListener("popstate", handlePopState);

    return () => {
      // Remove the event listener when the component unmounts
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("popstate", handlePopState);
    };
  }, [uploadinProgress]);

  const raiseAdditionalSchema = {
    amount: Yup.number()
      .required("Amount is required")
      .typeError("Amount is required")
      .min(50, "Minimum goal amount is $50"),
    goalDate: Yup.object()
      .typeError("required")
      .test(
        "is moment object",
        "provided date is not valid",
        (value) => value?._isAMomentObject || false
      )
      .test("min-date", "Please select a date after today.", (value) =>
        moment(value).isAfter(moment().add(0, "days"))
      )
      .test(
        "max-date",
        "Please choose a date that is within the 6 months.",
        (value) => moment(value).isBefore(moment().add(6, "months"))
      ),
  };

  const schema = Yup.object({
    title: Yup.string()
      .required("Title is required.")
      .trim()
      .min(3)
      .max(
        settings?.raiseTitleLimit,
        `Title must be at most ${settings?.raiseTitleLimit} characters.`
      ),
    postDescription: Yup.string()
      .trim()
      .max(
        settings?.raiseDescriptionLimit,
        `Description must be at most ${settings?.raiseDescriptionLimit} characters.`
      ),
    file: Yup.array()
      .required("Images/Videos file is required")
      .min(1)
      .max(10, "Sorry, you can only upload up to 10 files at a time")
      .test(
        "videos-size-validation",
        "Video is too big, please upload a video less than 100mb.",
        function (value) {
          let videoSizeError = false;
          Array.from(value).forEach((element) => {
            if (String(element.file.type).slice(0, 5) === "video") {
              if (element.file.size / 1048576 > 100) {
                videoSizeError = true;
              }
            }
          });
          if (videoSizeError) {
            return false;
          } else {
            return true;
          }
        }
      ),
    // .test(
    //   "videos-validation",
    //   "One Video is mandatory for create raise.",
    //   function (value) {
    //     let video = false;
    //     Array.from(value).forEach((element) => {
    //       if (String(element.file.type).slice(0, 5) === "video") {
    //         video = true;
    //       }
    //     });
    //     if (video) {
    //       return true;
    //     } else {
    //       return false;
    //     }
    //   }
    // )
    ...(chooseType === "campaign" ? raiseAdditionalSchema : {}),
  });
  const { register, handleSubmit, formState, setValue, watch } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: {
      goalDate: moment().add(30, "days"),
    },
  });
  const raiseFormData = watch();
  const manageUserConcern = useCallback(() => {
    const values = raiseFormData;
    let video = false;
    if (Array.isArray(values.file)) {
      Array.from(values.file).forEach((element) => {
        if (String(element.file.type).slice(0, 5) === "video") {
          video = true;
        }
      });
    }
    if (!video) {
      setVideoPopup(true);
      return;
    }

    console.log(user.email_verified);
    if (user.email_verified) {
      return createRaise();
    } else {
      return setOpenUserConcernDrawer(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [raiseFormData, user.email_verified]);
  const createRaise = useCallback(async () => {
    const values = raiseFormData;
    try {
      values.postDescription = postDescription;
      let video = false;
      if (Array.isArray(values.file)) {
        Array.from(values.file).forEach((element) => {
          if (String(element.file.type).slice(0, 5) === "video") {
            video = true;
          }
        });
      }
      if (!video) {
        setVideoPopup(true);
        return;
      }
      values.postDescription = postDescription;
      values.amount = chooseType === "campaign" ? values.amount : undefined;
      values.goalDate = chooseType === "campaign" ? values.goalDate : undefined;
      setDraftObject(values);
      // 1. check id verification
      let isVerified;
      if (!user.email_verified) {
        isVerified = await verifyUserIdentity(); // Verify the user identity

        // If not verified, open the email dialog and stop the process
        if (!isVerified) {
          setOpenEmailDialog(true);
          return;
        }
      }

      setLoading(true); // Show loading spinner during upload
      await uploadFileInAWS(values.file); // Upload files to AWS
      setFormValues(values);
    } catch (err) {
      // setStoryData(values);
      console.log(err);
      setSnack(
        "Something went wrong while uploading your videos/images. Please try again.",
        "error"
      );
      setUploadinProgress(false);
      setLoading(false);
    }
  }, [raiseFormData]);

  const verifyUserIdentity = React.useCallback(
    async function () {
      if (isUserVerified) {
        return true; // Already verified, return true immediately
      }
      try {
        let response = await API({
          url: "v2/accounts/check-verified-2",
          method: "post",
          data: { userId: userId },
        });
        const emailVerification = response?.data?.data?.email_verified?.email;
        if (emailVerification) {
          setIsUserVerified(true);
          // If the email is already verified, proceed to create the raise
          return true;
        } else {
          // If the email is not verified, open the verification dialog
          setOpenEmailDialog(true);
          return false;
        }

        // createRaise();
        // return true; // Verification successful
      } catch (err) {
        console.log("Error during identity verification:", err);
        return false; // Verification failed
      }
    },
    [isUserVerified]
  );
  const postDescriptionFormValue = watch("postDescription");
  const postTitleFormValue = watch("title");
  useEffect(() => {
    if (postDescriptionFormValue) {
      setPostDescription(postDescriptionFormValue);
    }
  }, [postDescriptionFormValue]);

  useEffect(() => {
    if (postTitleFormValue) {
      setTitle(postTitleFormValue);
    }
  }, [postTitleFormValue]);

  return (
    <>
      {openEmailDialog && (
        <EmailVerifyDialog
          open={openEmailDialog}
          setOpen={setOpenEmailDialog}
          verifyUserIdentity={verifyUserIdentity}
        />
      )}
      <DynamicMeta
        title="Create Raise | Ploom Social"
        description="Imagine having a closer, more personal connection with your community, participating in your raise, and sharing in the adventure. That’s exactly what Ploom’s community offers, helping you turn your campaign into reality."
      />

      {/* page title with back button */}
      <Stack
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          gap: { xs: "8px", md: "12px" },
        }}
      >
        <Typography
          component={"span"}
          sx={{
            color: darkMode ? "white.1000" : "black.1000",
            fontFamily: "Poppins",
            fontSize: { xs: "14px", sm: "20px", md: "24px" },
            fontStyle: "normal",
            fontWeight: { xs: 400, sm: "600" },
            lineHeight: "142%" /* 34.08px */,
            letterSpacing: "-0.408px",
          }}
        >
          Create a {chooseType === "campaign" ? "Raise" : "Story"}
        </Typography>

        {/* toggle switch */}
        <Stack
          component={Button}
          onClick={() => {
            setChooseType(chooseType === "campaign" ? "story" : "campaign");
          }}
          sx={{
            "&.MuiButtonBase-root": {
              borderRadius: "125px",
              backgroundColor: darkMode ? "bgDark.third" : "white.1000",
              border: { xs: "1px solid", md: "none" },
              borderColor: darkMode
                ? "#505050 !important"
                : "#F5F5F5 !important",
            },
            "&.MuiButtonBase-root:hover": {
              backgroundColor: darkMode ? "bgDark.third" : "white.1000",
            },

            position: "relative",
            borderRadius: "125px",
            background: "white.1000",
            backgroundColor: "white.1000",
            padding: { xs: "3px", md: "8px 10px" },
            cursor: "pointer",
          }}
        >
          <Box
            sx={{
              position: "relative",
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              "&::before": {
                content: '""',
                position: "absolute",
                top: "0%",
                left: "0%",
                transition: "all .5s ease-in-out",
                transform:
                  chooseType === "campaign"
                    ? "translateX(100%)"
                    : "translateX(0%)",
                width: "50%",
                height: "100%",
                background: "blue",
                borderRadius: "155px",
                zIndex: 1,
              },
            }}
          >
            <Stack
              sx={{
                padding: { xs: "4px 10px", md: "8px 30px" },
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                gap: { xs: "2px", sm: "4px", md: "8px" },
                borderRadius: "155px",
                zIndex: 3,
              }}
            >
              <StoryIcon
                color={
                  chooseType === "story"
                    ? palette.white[1000]
                    : palette.primary.disabledLight
                }
                sx={{
                  width: { xs: "14px", md: "20px" },
                  height: { xs: "14px", md: "20px" },
                  transition: "color .5s ease-in-out",
                }}
              />
              <Typography
                sx={{
                  transition: "color .5s ease-in-out",
                  textAlign: "center",
                  fontFamily: "Poppins",
                  fontSize: { xs: "12px", md: "18px" },
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "142%",
                  letterSpacing: "-0.229px",
                  color:
                    chooseType === "story"
                      ? "white.1000"
                      : "primary.disabledLight",
                }}
              >
                Story
              </Typography>
            </Stack>
            <Stack
              sx={{
                padding: { xs: "4px 10px", md: "8px 30px" },
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                gap: { xs: "0px", sm: "4px", md: "8px" },
                borderRadius: "155px",
                zIndex: 3,
              }}
            >
              <RaiseIcon
                color={
                  chooseType === "campaign"
                    ? palette.white[1000]
                    : palette.primary.disabledLight
                }
                sx={{
                  width: { xs: "11px", md: "20px" },
                  height: { xs: "11px", md: "20px" },
                  transition: "color .5s ease-in-out",
                }}
              />
              <Typography
                sx={{
                  transition: "color .5s ease-in-out",
                  textAlign: "center",
                  fontFamily: "Poppins",
                  fontSize: { xs: "12px", md: "18px" },
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "142% /* 25.56px */",
                  letterSpacing: "-0.408px",
                  color:
                    chooseType === "campaign"
                      ? "white.1000"
                      : "primary.disabledLight",
                }}
              >
                Raise
              </Typography>
            </Stack>
          </Box>
        </Stack>
      </Stack>
      <Stack
        // onSubmit={handleSubmit(createRaise)}
        onSubmit={handleSubmit(manageUserConcern)}
        component={"form"}
        sx={{
          mt: { xs: "15px", md: "30px" },
          display: "grid",
          gridTemplateColumns: { xs: "1fr", lg: "1fr 1fr" },
          padding: { md: "30px" },
          gap: "19.81px",
          backgroundColor: darkMode ? "bgDark.third" : "white.1000",
          borderRadius: "15px",
        }}
      >
        {/* form inputs */}

        {/* drag and drop input container */}
        <Stack>
          <DragAndDropInput
            onChange={setValue.bind(null, "file")}
            register={register}
            errors={formState.errors}
            disabled={uploadinProgress}
          />
        </Stack>

        {/* all input boxes */}
        <Stack>
          <Input
            name={"title"}
            inputLabel={"Add Title"}
            register={register}
            errors={formState.errors}
            placeholder="Example title"
            disabled={uploadinProgress}
            countDown={true}
            maxLength={settings.raiseTitleLimit}
            targetValue={title}
            onChange={(event) => {
              setTitle(
                String(event.target.value).slice(0, settings.raiseTitleLimit) ||
                  ""
              );
            }}
            onBlur={(event) => {
              setTitle(event.target.value?.trim() || "");
            }}
            value={title}
            maxlength={40}
          />
          <Input
            name={"postDescription"}
            inputLabel={"Add description"}
            placeholder="Share the story behind your campaign: What inspired you to start this fundraiser, what goals are you aiming to achieve, and what impact do you envision creating with your Raise?"
            multiline
            fullWidth
            rows={6}
            sx={{
              mt: "10px",
              "& fieldset": {
                border: "1px solid",
                borderColor: darkMode ? "neutral.650" : "white.400",
                borderRadius: "10px",
              },
              "& textarea::placeholder": {
                color: darkMode ? "white.1000" : "black.1000",
              },
              "& textarea,textarea:is(:hover,:focus)": {
                border: "none",
                zIndex: 2,
                color: darkMode ? "white.1000" : "black.1000",
                opacity: 1,
                mb: "20px",
              },
            }}
            register={register}
            errors={formState.errors}
            disabled={uploadinProgress}
            onChange={(event) => {
              setPostDescription(
                String(event.target.value).slice(
                  0,
                  settings.raiseDescriptionLimit
                ) || ""
              );
            }}
            onBlur={(event) => {
              setPostDescription(event.target.value?.trim() || "");
            }}
            value={postDescription}
            countDown={true}
            maxLength={settings.raiseDescriptionLimit}
            targetValue={postDescription}
            countDownSx={{ position: "absolute", bottom: "20px", right: "9px" }}
          />
          <Collapse in={Boolean(chooseType === "campaign" ? true : false)}>
            <Input
              inputLabel={"Goal"}
              type="number"
              placeholder="Min goal amount is $50"
              fullWidth
              name={"amount"}
              register={register}
              errors={formState.errors}
            />
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DemoContainer components={["DatePicker"]}>
                <DatePicker
                  label="Fundraiser end date"
                  defaultValue={moment().add(30, "days")}
                  minDate={moment().add(1, "days")}
                  maxDate={moment().add(6, "months")}
                  sx={{
                    mt: "20px",
                    width: "100%",
                    "& .MuiSvgIcon-root": {
                      color: darkMode ? "white.1000" : "black.350",
                    },
                    "& label.Mui-focused": {
                      color: darkMode ? "white.1000" : "primary.main",
                    },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "white",
                      },
                      "&:hover fieldset": {
                        borderColor: "white",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: darkMode ? "white.1000" : "black.1000",
                      },
                    },
                    "input:placeholder-shown": { border: "none" },
                    "input:focus": { border: "none" },
                    "input:hover": { border: "none" },
                    "& label, & label.Mui-disabled": {
                      color: darkMode ? "white.1000" : "primary.main",
                      fontSize: "16px",
                      fontWeight: 500,
                      fontStyle: "normal",
                      lineHeight: "normal",
                      textTransform: "capitalize",
                    },
                    "& input,input:is(:hover,:focus)": {
                      border: "none",
                      zIndex: 2,
                      color: darkMode ? "white.1000" : "black.1000",
                      opacity: 1,
                    },
                    "& fieldset": {
                      borderRadius: "10px",
                      border: "1px solid",
                      borderColor: darkMode ? "neutral.650" : "neutral.150",
                      paddingTop: "15px",
                      paddingBottom: "13px",
                    },
                  }}
                  onChange={(date) => {
                    setValue("goalDate", date);
                  }}
                  slotProps={{
                    textField: {
                      error: Boolean(formState.errors.goalDate),
                      helperText: formState.errors.goalDate?.message || "",
                    },
                  }}
                />
              </DemoContainer>
            </LocalizationProvider>
          </Collapse>

          {/* <Typography
            sx={{
              marginTop: "24px",
              color: darkMode ? "grey.900" : "black.350",
              opacity: darkMode ? 0.7 : 1,
              fontSize: "13px",
              fontWeight: 400,
              fontStyle: "normal",
              lineHeight: "24px",
            }}
          >
            A 8% processing fee is collected by PloomSocial, plus 2.9% credit
            card processing fee and 0.30 per transaction.
          </Typography> */}
          <ButtonWithLoading
            color="primary"
            disabled={formState.isSubmitting}
            isLoading={loading || formState.isSubmitting}
            size="13px"
            type="submit"
            variant="contained"
            sx={{
              background: palette.gradients["main"],
              mr: "0",
              alignSelf: "end",
              width: { xs: "100%", lg: "184px" },
              display: "flex",
              paddingBlock: "15px",
              borderRadius: "10px",
              textTransform: "capitalize",
              mt: "30px",
              "&.Mui-disabled": {
                color: alpha(palette.white["1000"], 0.5),
              },
            }}
            // onClick={() => {
            //   if (!user.email_verified) {
            //     console.log("verify");

            //     setOpenUserConcernDrawer(true);
            //   } else {
            //     console.log("not verify");
            //     createRaise();
            //   }
            // }}
          >
            Post campaign
          </ButtonWithLoading>
        </Stack>
      </Stack>

      <FileUploadingDialog
        openDialog={openDialog}
        totalFiles={totalFiles}
        setTotalFiles={setTotalFiles}
        currentFileIndex={currentFileIndex}
        message={`Hold on a moment, we are creating your raise`}
        uploadProgress={uploadProgress}
        createPost={createRaiseApiCall}
        type="Raise"
        showShare
        uploadError={uploadError}
        setOpenDialog={setOpenDialog}
        title={
          "Please don’t close the tabs while uploading files, Otherwise System won’t be able to create a raise."
        }
        awsUploading={awsUploading}
        setFinalVideo={setFinalVideo}
        setUploadProgress={setUploadProgress}
        setCurrentFileIndex={setCurrentFileIndex}
        isLoading={isLoading}
      />
      <DialogBox
        openDialog={openContactUsDialog}
        setOpenDialog={setOpenContactUsDialog}
        Content={({ handleClose }) => (
          <ContactUsDialog
            msg="Your KYC details are not verified, Please contact our customer care."
            handleClose={() => setOpenContactUsDialog(false)}
          />
        )}
      ></DialogBox>

      <DialogBox
        openDialog={videoPopup}
        setOpenDialog={setVideoPopup}
        Content={({ handleClose }) => (
          <VideoRequired cancelCall={handleClose} />
        )}
      ></DialogBox>

      <UserConcernDialog
        openDrawer={openUserConcernDrawer}
        setOpenDrawer={setOpenUserConcernDrawer}
        isLoading={loading}
        callback={createRaise}
      />
    </>
  );
};
