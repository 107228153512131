import React, { useEffect, useState } from "react";
import ButtonWithLoading from "../ui/button/ButtonWithLoading";
import { Box, Button, Stack, Typography, alpha } from "@mui/material";
import ClockIcon from "../../icons/ClockIcon";
import { MuiOtpInput } from "mui-one-time-password-input";
import { useSnack } from "../../hooks/store/useSnack";
import { useTheme } from "@emotion/react";
import useThemeStore from "../../hooks/store/useThemeStore";
import { useSignup } from "../../hooks/store/useSignup";
import useApi from "../../hooks/useApi";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
// icons
// import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";

const VarifyOtpForm = ({ openNextForm }) => {
  const { setSnack } = useSnack();
  const { typography, palette } = useTheme();
  const { darkMode } = useThemeStore();
  const {
    mobileNumber,
    username,
    email,
    code,
    password,
    setUserId,
    orgName,
    EIN,
    accountType,
  } = useSignup();
  const { apiCall } = useApi(true);
  const [timer, setTimer] = useState(59);
  const [isResendDisabled, setIsResendDisabled] = useState(true);
  const [isWrongOtp, setIsWrongOtp] = useState(false);
  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState("");

  useEffect(() => {
    // Start the countdown timer when the component mounts
    const interval = setInterval(() => {
      if (timer > 0) {
        setTimer(timer - 1);
      } else {
        setIsResendDisabled(false); // Enable the "Resend OTP" button when the timer reaches 0
        clearInterval(interval); // Stop the timer
      }
    }, 1000);

    return () => {
      clearInterval(interval); // Clean up the interval when the component unmounts
    };
  }, [timer]);

  const handleChange = (newValue) => {
    setOtp(newValue);
  };

  // code for varify otp
  const handleComplete = async (finalValue) => {
    try {
      setLoading(true);
      setIsWrongOtp(false);
      await apiCall({
        url: "v2/accounts/verify-otp",
        method: "post",
        data: { code: code, mobile: mobileNumber, OTP: finalValue },
      });

      // create a new account
      await signupUser();
      setLoading(false);
      openNextForm("Profile");
      // openNextForm("EmailVerify");
      // setSnack(
      //   "Please verify your email address to complete the registration process. We've sent a verification link to your email address.",
      //   "success"
      // );
    } catch (error) {
      console.log(error);
      setIsWrongOtp(true);
      setLoading(false);
      setSnack(
        "Invalid OTP: The OTP you entered is incorrect. Please double-check the code and try again.",
        "error"
      );
    }
  };

  // code for resend otp
  const ResendOtp = async () => {
    try {
      setIsResendDisabled(true);
      setTimer(59);
      let result = await apiCall({
        url: "v2/accounts/get-otp",
        method: "post",
        data: {
          code: code,
          mobile: mobileNumber,
          OTP_description: "registration",
        },
      });
      console.log(result);
      setSnack(
        "We've successfully resent the OTP to your mobile number. Please check your messages for the new code.",
        "success"
      );
    } catch (error) {
      setSnack(error.response.data.message, "error");
    }
  };

  // code for signup user after mobile varification
  const signupUser = async () => {
    let message = "account created successfully";
    let type = "success";
    const data = {
      username: username,
      password: password,
      email: email,
      mobile: mobileNumber,
      code: code,
      terms_condition: true,
      orgName: orgName,
      ein: EIN,
      accountType: accountType._id,
    };
    try {
      let res = await apiCall({
        url: "v2/accounts/create",
        method: "post",
        data,
      });
      setUserId(res.data.data.id);
      if (res.code === 201) {
        return true;
      }
    } catch (error) {
      console.log(error.code);
      console.log(error);
      message = "server error";
      type = "error";
      if (error?.response?.status === 404) {
        message = "username or email is already taken";
      }
    }
    setSnack(message, type);
  };
  return (
    <>
      <Box
        component={"form"}
        sx={{
          paddingInline: { xs: "20px", md: "30px", lg: "60px" },
          paddingBlock: { xs: "20px", lg: "50px" },
          backgroundColor: darkMode ? "bgDark.third" : "white.1000",
          borderRadius: { xs: "10px", lg: "18px" },
          marginTop: { xs: 0, lg: "50px" },
          boxShadow: "0px 0px 100px rgba(0, 0, 0, 0.06)",
          height: { xs: "85vh", md: "unset" },
        }}
      >
        <Box
          sx={{
            maxWidth: "431px",
            margin: "0 auto",
            height: { xs: "100%", md: "unset" },
            display: "flex",
            flexDirection: "column",
          }}
        >
          {/* form headings with back button */}
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: { xs: "10px", md: "14px" },
            }}
          >
            {/* <Button
              disableRipple={true}
              sx={{
                minWidth: "unset",
                backgroundColor: {
                  xs: darkMode ? "grey.550" : "white.1150",
                  md: darkMode ? "grey.550" : "white.1000",
                },
                flexShrink: "0",
                padding: "10px",
                borderRadius: "10px",
              }}
              onClick={() => {
                openNextForm("Registration");
              }}
            >
              <ArrowBackIosNewRoundedIcon
                sx={{
                  color: darkMode ? "white.1000" : "black.1000",
                  marginLeft: "0",
                }}
                fontSize="18px"
              />
            </Button> */}
            <Typography
              sx={{
                fontFamily: typography.manrope,
                color: darkMode ? "white.1000" : "black.1000",
                fontSize: { xs: "18px", sm: "24px", lg: "36px" },
                fontWeight: { xs: 800, lg: 700 },
                lineHeight: { xs: "40px", lg: "122%" },
                letterSpacing: { xs: "-0.52px", md: "-0.72px" },
              }}
            >
              Verify Phone number
            </Typography>
          </Stack>
          <Typography
            sx={{
              mt: "10px",
              fontFamily: typography.manrope,
              color: darkMode ? "neutral.450" : "neutral.550",
              fontSize: { xs: "18px", lg: "16px" },
              fontWeight: 400,
              lineHeight: "152%",
            }}
          >
            A verification code has been sent on {mobileNumber}. Enter the code
            below.
          </Typography>
          <Box sx={{ marginTop: { xs: "20px", md: "15px" } }}>
            <MuiOtpInput
              TextFieldsProps={{ placeholder: "0", type: "number" }}
              value={otp}
              length={6}
              onChange={handleChange}
              autoFocus
              onComplete={handleComplete}
              sx={{
                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                  {
                    display: "none",
                  },
                "& input[type=number]": {
                  MozAppearance: "textfield",
                },
                " & .MuiOutlinedInput-input": {
                  color: darkMode ? "white.1000" : "neutral.650",
                  width: {
                    xs: "30px",
                    ss: "45px",
                    sm: "50px",
                    md: "59px",
                  },
                  height: {
                    xs: "30px",
                    ss: "45px",
                    sm: "50px",
                    md: "59px",
                  },
                  padding: 0,
                  borderRadius: "8px",
                },
                "& .MuiOutlinedInput-input": {
                  fontFamily: "manrope",
                  fontSize: "16px",
                  border: "1px solid",
                  borderColor: darkMode ? "neutral.650" : "bgWhite.secondary",
                  borderRadius: "4px",
                },
                "&.MuiOtpInput-Box": {
                  flexWrap: "nowrap",
                  justifyContent: "space-between",
                  gap: { xs: "5px", sm: "10px", md: "15px" },
                },
              }}
            />
          </Box>
          {/* alert message */}
          {isWrongOtp && (
            <Stack
              sx={{
                mt: "15px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "10px",
                color: "error.light",
              }}
            >
              <ErrorOutlineIcon fontSize="small" />
              <Typography>
                Invalid OTP: The OTP you entered is incorrect.
              </Typography>
            </Stack>
          )}
          <Stack
            sx={{
              mt: { xs: "15px", md: "17px" },
              flex: "1",
              display: "flex",
              flexDirection: "row",
              alignItems: { xs: "baseline", md: "center" },
              justifyContent: "space-between",
            }}
          >
            <Stack
              display={"flex"}
              flexDirection="row"
              alignItems="center"
              gap={"10px"}
            >
              <ClockIcon
                color={
                  darkMode ? palette.white["1000"] : palette.primary["main"]
                }
                width="16px"
                height="16px"
              />
              <Typography
                sx={{
                  color: darkMode ? "white.1000" : "primary.main",
                  fontFamily: typography.manrope,
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "146%",
                }}
              >
                00:{timer < 10 ? `0${timer}` : timer}
              </Typography>
            </Stack>

            {isResendDisabled ? (
              <Button
                variant="text"
                sx={{
                  "&.Mui-disabled": {
                    color: darkMode ? "white.1000" : "primary.main",
                    opacity: 0.3,
                    textTransform: "capitalize",
                    pointerEvents: "all",
                    cursor: "no-drop",
                  },
                }}
                disabled
              >
                Resend OTP
              </Button>
            ) : (
              <Button
                variant="text"
                onClick={ResendOtp}
                sx={{
                  color: darkMode ? "white.1000" : "primary.main",
                  textTransform: "unset",
                  fontFamily: typography.manrope,
                  fontSize: "14px",
                  fontWeight: 500,
                  opacity: 0.6,
                }}
              >
                Resend OTP
              </Button>
            )}
          </Stack>
          <ButtonWithLoading
            disabled
            //   disabled={formState.isSubmitting}
            isLoading={loading}
            fullWidth
            variant="contained"
            size="large"
            sx={{
              fontFamily: typography.manrope,
              fontSize: "16px",
              fontWeight: 500,
              lineHeight: "152%",
              padding: "14px 12px",
              marginTop: { xs: "25px", lg: "30px" },
              borderRadius: "10px",
              textTransform: "capitalize",
              "&.Mui-disabled": {
                color: darkMode
                  ? alpha(palette.white["1000"], 0.7)
                  : "white.1000",
                backgroundColor: darkMode
                  ? "primary.disabledDark"
                  : "primary.disabledLight",
              },
            }}
          >
            Verify
          </ButtonWithLoading>
        </Box>
      </Box>
    </>
  );
};

export default VarifyOtpForm;
